/** Auto-completion menu */
html {
  --autocomplete-muted-color: #969faf;
}

#algolia-autocomplete-container .aa-dropdown-menu .before_special, .aa-Panel .before_special {
  color: #aaaaaa;
  text-decoration: line-through;
  font-size: 12px;
}

#algolia-autocomplete-container .aa-dropdown-menu .tier_price, .aa-Panel .tier_price {
  color: #666666;
  font-size: 10px;
}

#algolia-autocomplete-container .aa-dropdown-menu .tier_price .tier_value, .aa-Panel .tier_price .tier_value {
  color: #54A5CD;
  font-size: 12px;
}

#algolia-autocomplete-container .aa-dropdown-menu .info-without-thumb .category-tag, .aa-Panel .info-without-thumb .category-tag {
  color: #3284b6;
}

#algolia-autocomplete-container .aa-dropdown-menu .info-without-thumb .details, .aa-Panel .info-without-thumb .details {
  font-size: 10px;
  color: #666;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  height: 30px;
  max-height: 30px;
  display: -webkit-box;
}

#algolia-autocomplete-container .aa-dropdown-menu .info-without-thumb .details em, .aa-Panel .info-without-thumb .details em {
  color: #222222;
}

#algolia-autocomplete-tt.algolia-autocomplete {
  width: 100%;
  display: inline-block !important;
}

#algolia-autocomplete-container .aa-dropdown-menu, .aa-Panel {
  position: absolute;
  right: 0;
  width: 100%;
  z-index: 1000 !important;
  border: 1px solid #ccc;
  background: white;
  margin-top: 0.5rem;
  box-shadow: 0px 2px 4px 0 rgba(22, 45, 57, 0.2);
}

.aa-Panel .aa-List {
  padding-left: 0px;
  margin: 0;
}

.aa-Panel .aa-List li.aa-Item {
  text-indent: 0;
}

@media (min-width: 992px) {
  #algolia-autocomplete-container .aa-dropdown-menu, .aa-Panel {
    min-width: 800px !important;
  }
}
@media (min-width: 769px) {
  #algolia-autocomplete-container .aa-dropdown-menu, .aa-Panel {
    width: 71.1%;
    min-width: 680px;
    padding-left: 8px;
  }
  .aa-Panel.productColumn1 section:nth-child(1) .aa-List {
    columns: 1;
    -webkit-columns: 1;
    -moz-columns: 1;
  }
  .aa-Panel.productColumn1 section:nth-child(1) .aa-List li {
    width: 285px;
  }
  .aa-Panel.productColumn2 section:nth-child(2) .aa-List {
    columns: 1;
    -webkit-columns: 1;
    -moz-columns: 1;
  }
  .aa-Panel ul {
    padding-inline-start: 0px;
    margin-bottom: 0px !important;
    margin-left: 0;
  }
}
.aa-Panel.productColumn2 section:nth-child(2) .aa-List li:hover, .aa-Panel.productColumn1 section .aa-List li:hover {
  background-color: rgba(230, 121, 33, 0.2);
}

#algolia-autocomplete-container .aa-dropdown-menu .col-2, .aa-Panel .col-2 {
  position: relative;
}

#algolia-autocomplete-container .aa-dropdown-menu .col-2 .col-left, .aa-Panel .col-2 .col-left {
  width: 67%;
}

#algolia-autocomplete-container .aa-dropdown-menu .col-2 .col-right, .aa-Panel .col-2 .col-right {
  width: 33%;
}

#algolia-autocomplete-container .aa-dropdown-menu .aa-no-results-products, .aa-Panel .aa-no-results-products {
  padding: 40px 40px;
  min-height: 250px;
}

#algolia-autocomplete-container .aa-dropdown-menu .aa-no-results-products .title, .aa-Panel .aa-no-results-products .title {
  font-weight: bold;
  margin-bottom: 30px;
  font-size: 16px;
}

#algolia-autocomplete-container .aa-dropdown-menu .aa-no-results-products .suggestions, .aa-Panel .aa-no-results-products .suggestions {
  margin-bottom: 30px;
}

#algolia-autocomplete-container .aa-dropdown-menu .aa-no-results-products .see-all a, .aa-Panel .aa-no-results-products .see-all a {
  color: #636363;
  font-weight: bold;
}

#algolia-autocomplete-container .aa-dropdown-menu .aa-no-results, .aa-Panel .aa-no-results {
  padding: 10px;
  font-style: italic;
}

#algolia-autocomplete-container .aa-dropdown-menu .category, .aa-Panel .category, .aa-Panel .aa-PanelLayout section .aa-SourceHeader {
  margin: 0 1.5rem;
  color: #a3a8b2;
  text-align: left;
  font-size: 88%;
  text-transform: uppercase;
  font-weight: bold;
}

#algolia-autocomplete-container .aa-dropdown-menu .category-suggestions, .aa-Panel .category-suggestions {
  padding: 4px;
  color: #54A5CD;
  text-align: left;
  font-size: 0.7em;
  text-transform: uppercase;
}

#algolia-autocomplete-container .aa-dropdown-menu .algoliasearch-autocomplete-hit, .aa-Panel .algoliasearch-autocomplete-hit {
  display: flex;
  position: relative;
  padding: 5px 10px;
  text-align: left;
  text-decoration: none;
  overflow: hidden;
  border-bottom: 0;
  background: none;
}

#algolia-autocomplete-container .aa-dropdown-menu .other-sections .aa-dataset-suggestions .algoliasearch-autocomplete-hit {
  padding-left: 30px;
}

.aa-Panel .aa-ItemLink.algolia-suggestions:has(svg.algolia-glass-suggestion) {
  position: relative;
  padding: 5px 0 5px 25px !important;
}

.aa-Panel .aa-ItemLink.algolia-suggestions svg.algolia-glass-suggestion.magnifying-glass {
  display: none;
}

.aa-Panel .aa-ItemLink.algolia-suggestions:has(svg.algolia-glass-suggestion) svg.algolia-glass-suggestion.magnifying-glass {
  display: block;
  position: absolute;
  right: auto;
  left: 5px;
  top: 7px;
  fill: #A6A6A6;
  stroke: #A6A6A6;
}

#algolia-autocomplete-container .aa-dropdown-menu.aa-without-products .col9, .aa-Panel .col9:hover {
  background-color: #F9F9F9;
}

#algolia-autocomplete-container .aa-dropdown-menu.aa-without-products #autocomplete-products-footer {
  display: none;
}

#algolia-autocomplete-container .aa-dropdown-menu .aa-dataset-products .aa-suggestion, .aa-Panel .aa-dataset-products .aa-suggestion {
  display: inline-block;
  width: 100%;
}

@media (min-width: 769px) {
  #algolia-autocomplete-container .aa-dropdown-menu .aa-dataset-products .aa-suggestion, .aa-Panel .aa-dataset-products .aa-suggestion {
    display: inline-block;
    width: 50%;
  }
}
#algolia-autocomplete-container .aa-dropdown-menu .aa-dataset-products .algoliasearch-autocomplete-hit, .aa-Panel .aa-dataset-products .algoliasearch-autocomplete-hit {
  padding: 15px 10px;
}

.autocomplete-wrapper, .aa-Panel {
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
  flex-wrap: wrap;
  border-radius: 0 0 0.3125rem 0.3125rem;
  border-bottom: 4px solid #73bf44;
}

.autocomplete-wrapper:after, .aa-Panel:after {
  clear: both;
  content: "";
}

#algolia-autocomplete-container .col9, .aa-Panel .col9 {
  float: right;
  box-sizing: border-box;
}

#algolia-autocomplete-container .col3, .aa-Panel .col3 {
  float: right;
  box-sizing: border-box;
}

#algolia-autocomplete-container.reverse .col3, .aa-Panel.reverse .col3 {
  float: left;
  min-width: 100%;
}

#algolia-autocomplete-container.reverse .col9, .aa-Panel.reverse .col9 {
  float: left;
  min-width: 100%;
  display: flex;
  height: 100%;
  flex: 1;
}

@media (min-width: 769px) {
  #algolia-autocomplete-container .col9, .aa-Panel .col9 {
    border-left: solid 1px #eeeeee;
    width: 70%;
    right: 0;
    height: 100%;
  }
  #algolia-autocomplete-container .col3, .aa-Panel .col3 {
    float: left;
    width: 30%;
  }
  #algolia-autocomplete-container.reverse .col3, .aa-Panel.reverse .col3 {
    float: left;
    width: 30%;
  }
  #algolia-autocomplete-container.reverse .col9, .aa-Panel.reverse .col9 {
    border-right: solid 1px #eeeeee;
    float: left;
    width: 70%;
  }
}
#algolia-autocomplete-container .other-sections, .aa-Panel .other-sections {
  margin: 20px 10px 70px;
}

.aa-dataset-products .aa-suggestions {
  margin: 10px auto 10px auto;
}

.aa-dataset-suggestions {
  display: none;
}

@media (min-width: 769px) {
  .aa-dataset-suggestions {
    display: block;
  }
}
@media (min-width: 769px) {
  .aa-dataset-products .aa-suggestions {
    margin: 10px auto 50px auto;
  }
}
.aa-dataset-products .aa-suggestions:after {
  content: "";
  display: block;
  clear: both;
}

#algolia-autocomplete-container .aa-dropdown-menu .other-sections .algoliasearch-autocomplete-hit, .aa-Panel .other-sections .algoliasearch-autocomplete-hit {
  padding-left: 10px;
}

#algolia-autocomplete-container .aa-dropdown-menu .other-sections .aa-suggestions, .aa-Panel .other-sections .aa-suggestions {
  margin-bottom: 20px;
}

#algolia-autocomplete-container .aa-dropdown-menu .aa-cursor .algoliasearch-autocomplete-hit, .aa-Panel .aa-cursor .algoliasearch-autocomplete-hit {
  background-color: rgba(230, 121, 33, 0.2);
}

#algolia-autocomplete-container .aa-dropdown-menu .algoliasearch-autocomplete-hit em, .aa-Panel .algoliasearch-autocomplete-hit em {
  font-weight: bold;
  font-style: normal;
}

#algolia-autocomplete-container .aa-dropdown-menu .algoliasearch-autocomplete-price, .aa-Panel .algoliasearch-autocomplete-price {
  font-size: 1.1em;
  color: #54A5CD;
  height: 22px;
}

#algolia-autocomplete-container .aa-dropdown-menu .algoliasearch-autocomplete-hit .thumb, .aa-Panel .algoliasearch-autocomplete-hit .thumb {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

#algolia-autocomplete-container .aa-dropdown-menu .algoliasearch-autocomplete-hit .thumb img, .aa-Panel .algoliasearch-autocomplete-hit .thumb {
  max-width: 50px;
}

#algolia-autocomplete-container .aa-dropdown-menu .algoliasearch-autocomplete-hit .info, .aa-Panel .algoliasearch-autocomplete-hit .info {
  color: #333333;
  margin-left: 1rem;
  font-size: 88%;
}

#autocomplete-products-footer {
  background-color: #F9F9F9;
  text-align: center;
  display: none;
  position: absolute;
  width: 70%;
  padding: 10px 0;
  bottom: 0;
  left: 30%;
}

#algolia-autocomplete-container.reverse #autocomplete-products-footer {
  right: auto;
  left: 0;
}

@media (min-width: 769px) {
  #autocomplete-products-footer {
    display: block;
  }
}
#autocomplete-products-footer span {
  color: #15769c;
  font-weight: 600;
}

#algolia-autocomplete-container .aa-dropdown-menu .algoliasearch-autocomplete-hit .info .algoliasearch-autocomplete-category, .aa-Panel .algoliasearch-autocomplete-hit .info .algoliasearch-autocomplete-category {
  font-size: 0.8em;
  color: #666;
  white-space: nowrap;
  overflow: hidden;
  max-width: 100%;
  text-overflow: ellipsis;
}

#algolia-autocomplete-container .aa-dropdown-menu .algoliasearch-autocomplete-hit .info .algoliasearch-autocomplete-category em, .aa-Panel .algoliasearch-autocomplete-hit .info .algoliasearch-autocomplete-category em {
  color: #222;
}

.algoliasearch-autocomplete-hit .info .algoliasearch-autocomplete-category span:nth-child(n+2):before {
  content: " | ";
}

.algoliasearch-autocomplete-hit .info .algoliasearch-autocomplete-price .after_special:after {
  content: " ";
}

.algoliasearch-autocomplete-hit .info .algoliasearch-autocomplete-price .tier_price {
  padding-left: 5px;
}

#algolia-autocomplete-container .aa-dropdown-menu .footer_algolia {
  position: absolute;
  width: 100%;
  padding: 10px;
  text-align: center;
  bottom: 0;
  left: 4px;
  font-size: 13px;
}

#algolia-autocomplete-container.reverse .aa-dropdown-menu .footer_algolia {
  left: auto;
  right: 4px;
}

@media (min-width: 769px) {
  #algolia-autocomplete-container .aa-dropdown-menu .footer_algolia {
    width: 30%;
  }
}
#algolia-autocomplete-container .aa-dropdown-menu .footer_algolia span {
  color: #B8B8B8;
  font-size: 10px;
}

#algolia-autocomplete-container .aa-dropdown-menu .footer_algolia img {
  display: inline;
  height: 1.5em;
  vertical-align: bottom;
  max-width: 130px;
}

#algolia-searchbox .algolia-search-input:focus:not([value=""]) {
  background: transparent;
}

#algolia-searchbox .algolia-search-input {
  position: static !important;
}

#algolia-searchbox .algolia-search-input:focus {
  outline: 0;
  box-shadow: none;
  border: solid 1px #54A5CD;
}

#algolia-autocomplete-container:after, .autocomplete-wrapper:after {
  clear: both;
  content: "";
}

#algolia-searchbox {
  position: relative;
}

#algolia-searchbox .clear-cross, #algolia_instant_selector .clear-cross {
  position: absolute;
  display: none;
  background: url("data:image/svg+xml;utf8,<svg width='12' height='12' viewBox='0 0 12 12' xmlns='http://www.w3.org/2000/svg' opacity='0.6'><path d='M.566 1.698L0 1.13 1.132 0l.565.566L6 4.868 10.302.566 10.868 0 12 1.132l-.566.565L7.132 6l4.302 4.3.566.568L10.868 12l-.565-.566L6 7.132l-4.3 4.302L1.13 12 0 10.868l.566-.565L4.868 6 .566 1.698z'></path></svg>") no-repeat center center/contain;
  cursor: pointer;
  width: 16px;
  height: 16px;
}

#algolia-searchbox .clear-query-autocomplete {
  bottom: 8px;
  right: 9px;
}

#algolia-searchbox .magnifying-glass {
  background: url("data:image/svg+xml;utf8,<svg width='40' height='40' viewBox='0 0 40 40'  fill='%23A6A6A6' xmlns='http://www.w3.org/2000/svg'><path d='M15.553 31.107c8.59 0 15.554-6.964 15.554-15.554S24.143 0 15.553 0 0 6.964 0 15.553c0 8.59 6.964 15.554 15.553 15.554zm0-3.888c6.443 0 11.666-5.225 11.666-11.668 0-6.442-5.225-11.665-11.668-11.665-6.442 0-11.665 5.223-11.665 11.665 0 6.443 5.223 11.666 11.665 11.666zm12.21 3.84a2.005 2.005 0 0 1 .002-2.833l.463-.463a2.008 2.008 0 0 1 2.833-.003l8.17 8.168c.78.78.78 2.05-.004 2.833l-.462.463a2.008 2.008 0 0 1-2.834.004l-8.168-8.17z' fill-rule='evenodd'/></svg>") no-repeat center right/20px;
  border: none;
  bottom: 7px;
  box-shadow: none;
  height: 20px;
  position: absolute;
  right: 7px;
  width: 20px;
}

#algolia_instant_selector .cross-wrapper .clear-refinement {
  display: block;
  position: relative;
  top: 5px;
  left: 5px;
}

#algoliaAutocomplete {
  position: relative;
}

#algoliaAutocomplete .aa-SubmitButton {
  background: none;
  border: none;
  position: absolute;
  right: 0px;
  height: 32px;
  box-shadow: none;
}

#algoliaAutocomplete .aa-ClearButton {
  position: absolute;
  right: 1px;
  top: 1px;
  height: 30px;
  border: none;
  background: #fff;
  display: none;
}

#algoliaAutocomplete .aa-LoadingIndicator {
  position: absolute;
  top: 20%;
  right: 10px;
}

.aa-Panel li.aa-Item {
  list-style-type: none;
  margin: 0 1.5rem;
}

.aa-Panel .aa-List li.aa-Item {
  padding: 0.25rem 0;
  border-bottom: 1px dotted #ccc;
}
.aa-Panel .aa-List li.aa-Item:last-child {
  border-bottom: 0;
}

.aa-Panel .aa-PanelLayout {
  display: grid;
  /*grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);*/
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  padding: 1rem 0;
}

.aa-Panel .aa-PanelLayout section {
  padding-top: 10px;
}

.aa-Panel.productColumn2 .aa-PanelLayout section:nth-child(1) li a, .aa-Panel.productColumn1 .aa-PanelLayout section:nth-child(2) li a, .aa-Panel .aa-PanelLayout section:nth-child(3) li a, .aa-Panel .aa-PanelLayout section:nth-child(4) li a, .aa-Panel .aa-PanelLayout section:nth-child(5) li a {
  color: inherit;
  display: inline-block;
  width: auto;
  padding: 5px 10px;
}

.aa-Panel.productColumn2 .aa-PanelLayout section:nth-child(1) li a:hover, .aa-Panel.productColumn1 .aa-PanelLayout section:nth-child(2) li a:hover, .aa-Panel .aa-PanelLayout section:nth-child(3) li a:hover, .aa-Panel .aa-PanelLayout section:nth-child(4) li a:hover, .aa-Panel .aa-PanelLayout section:nth-child(5) li a:hover {
  background-color: rgba(230, 121, 33, 0.2);
  text-decoration: none;
}

.footer_algolia {
  position: absolute;
  bottom: 0;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  align-items: flex-start;
}

.footer_algolia .algolia-search-by-label {
  color: var(--autocomplete-muted-color);
  font-size: 0.9em;
  margin-right: 5px;
}

.footer_algolia img {
  min-width: 80px;
}

@media (min-width: 992px) {
  .aa-Panel.productColumn2 .aa-PanelLayout section:nth-child(2), .aa-Panel.productColumn1 .aa-PanelLayout section:nth-child(1) {
    margin-left: 2.5rem !important;
    min-width: 570px !important;
  }
}
@media (min-width: 769px) {
  .aa-Panel.productColumn2 .aa-PanelLayout section:nth-child(1) {
    grid-area: 1/1/2/2;
    width: 180px;
  }
  .aa-Panel.productColumn2 .aa-PanelLayout section:nth-child(2) {
    border-left: 1px solid #ccc;
    margin-bottom: 0px;
    min-width: 480px;
    grid-area: 1/2/5/3;
  }
  .aa-Panel.productColumn1 .aa-PanelLayout section:nth-child(1) {
    border-left: 1px solid #ccc;
    margin-bottom: 0px;
    min-width: 48px;
    grid-area: 1/2/5/3;
  }
  .aa-Panel.productColumn1 .aa-PanelLayout section:nth-child(2) {
    grid-area: 1/1/2/2;
    padding-top: 20px;
    width: 180px;
  }
  .aa-Panel .aa-PanelLayout section:nth-child(3) {
    grid-area: 2/1/3/2;
    width: 180px;
  }
  .aa-Panel .aa-PanelLayout section:nth-child(4) {
    grid-area: 3/1/4/2;
    width: 180px;
  }
  .aa-Panel .aa-PanelLayout section:nth-child(5) {
    grid-area: 4/1/5/2;
    width: 180px;
  }
}
@media (max-width: 768px) {
  .aa-Panel .aa-PanelLayout {
    justify-content: flex-start;
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  .footer_algolia {
    width: 100%;
    justify-content: center;
  }
  #autocomplete-products-footer {
    left: 0;
    width: 100%;
    padding-left: 8px;
    padding-right: 8px;
    box-sizing: border-box;
  }
  .footer_algolia {
    text-align: center;
    padding-top: 15px;
  }
  .aa-Panel .aa-PanelLayout section {
    padding-left: 8px;
    padding-right: 8px;
    box-sizing: border-box;
  }
  #algoliaAutocomplete {
    margin-bottom: 10px;
    clear: both;
  }
  #algoliaAutocomplete.block-search input {
    left: inherit;
    position: relative;
    margin: 0px;
  }
  #algoliaAutocomplete .aa-SubmitButton {
    z-index: 2;
  }
}
.aa-Panel mark {
  color: #333333;
  background-color: rgba(250, 215, 28, 0.75);
  font-weight: 700;
}

.aa-Panel .aa-PanelLayout section .aa-SourceNoResults {
  margin: 0 1.5rem;
}

.clear-query-autocomplete {
  display: none !important;
}